/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*----------------------------------------------------------------------*/
/*----------------------------------------------------------------------*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import {ProductBox} from '../../components/boxen';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li",
    a: "a",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, "\n", React.createElement(Card, {
    sx: {
      paddingLeft: '1em',
      paddingBottom: '1em'
    }
  }, React.createElement(Box, {
    display: "flex",
    alignItems: "baseline"
  }, React.createElement(Box, null, React.createElement(_components.h1, null, "Selling Partner API Implementations")), React.createElement(Box, {
    ml: 2
  }, React.createElement(_components.p, null, "Getting started, exploring with postman"))), React.createElement(Box, {
    display: "flex",
    gap: "20px",
    justifyContent: "center"
  }, React.createElement(ProductBox, {
    maintitle: "Contents"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#overview"
  }, "Overview")), "\n")), React.createElement(ProductBox, {
    maintitle: "Resources"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developer-docs.amazon.com/sp-api"
  }, "amzn docs")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/amzn/selling-partner-api-docs/wiki/Using-Postman-for-Sellling-Partner-API-models"
  }, "amzn postman selling partner api models")), "\n")))), "\n", "\n", React.createElement("a", {
    name: "overview"
  }), "\n", React.createElement(Card, {
    sx: {
      padding: '0em 0em 1em 1em',
      marginTop: '2em'
    }
  }, React.createElement(_components.h2, null, "Overview")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
